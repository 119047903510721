export const formatDate = (isoString) => {
  if (!isoString) return 'Not specified';
  return new Date(isoString).toLocaleString();
};

export const getDaysRemaining = (deadlineDate) => {
  if (!deadlineDate) return null;
  
  const deadline = new Date(deadlineDate);
  const today = new Date();
  
  // Reset time portion for accurate day calculation
  deadline.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  
  const diffTime = deadline - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return diffDays;
};

export const getDeadlineColor = (daysRemaining) => {
  if (daysRemaining === null) return 'text-gray-500';
  if (daysRemaining < 0) return 'text-red-500';
  if (daysRemaining <= 3) return 'text-red-500';
  if (daysRemaining <= 7) return 'text-orange-500';
  if (daysRemaining <= 14) return 'text-yellow-600';
  return 'text-green-500';
};

export const getDeadlineText = (daysRemaining) => {
  if (daysRemaining === null) return 'No deadline';
  if (daysRemaining < 0) return 'Expired';
  if (daysRemaining === 0) return 'Due today';
  if (daysRemaining === 1) return '1 day';
  return `${daysRemaining} days`;
}; 