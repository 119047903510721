import React from 'react';
import { motion } from 'framer-motion';
import { usePageTransition } from '../../hooks/useAnimations';

function RouteTransition({ children }) {
  const pageTransition = usePageTransition();

  return (
    <motion.div
      {...pageTransition}
      className="min-h-screen"
    >
      {children}
    </motion.div>
  );
}

export default RouteTransition; 