import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiConfig from '../config/api';

export const checkAuth = () => {
    return fetch(`${apiConfig.BASE_URL}/api/check-auth`, {
        credentials: 'include',
    })
    .then(res => res.json())
    .then(data => {
        return data.authenticated;
    })
    .catch(() => false);
};

export const requireAuth = (Component) => {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            checkAuth()
                .then(isAuthenticated => {
                    if (!isAuthenticated) {
                        navigate('/login');
                    }
                    setLoading(false);
                });
        }, [navigate]);

        if (loading) {
            return <div>Loading...</div>;
        }

        return <Component {...props} />;
    };
};

export const logout = () => {
    // Clear localStorage
    localStorage.clear();
    
    // Clear cookies
    document.cookie.split(";").forEach(function(c) { 
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
    });
    
    // Redirect to login
    window.location.href = '/login';
};
