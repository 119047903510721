import React, { useState, useEffect } from 'react';
import CreateFolderModal from '../components/folders/CreateFolderModal';
import { Link } from 'react-router-dom';
import { endpoints } from '../config/api';

function SavedOpportunities() {
  const [folders, setFolders] = useState([]);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    setLoading(true);
    try {
      const response = await fetch(endpoints.folders.getAll, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        }
      });
      if (!response.ok) throw new Error('Failed to fetch folders');
      const data = await response.json();
      
      const foldersWithCounts = await Promise.all(data.map(async (folder) => {
        const contentsResponse = await fetch(`${endpoints.folders.getContents}/${folder.id}`, {
          credentials: 'include'
        });
        const contents = await contentsResponse.json();
        return {
          ...folder,
          opportunity_count: Array.isArray(contents) ? contents.length : 0
        };
      }));
      setFolders(foldersWithCounts);
    } catch (error) {
      console.error('Error fetching folders:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50">
      <div className="container mx-auto py-12">
        {/* Header */}
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-blue-800 mb-2">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
              Saved Opportunities
            </span>
          </h1>
          <p className="text-xl text-gray-600">
            Select a folder to view its opportunities
          </p>
        </header>

        {/* Folder Grid */}
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {folders.map(folder => (
            <Link
              key={folder.id}
              to={`/folders/${folder.id}`}
              className="bg-white rounded-xl p-6 shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 group"
            >
              <div className="flex items-center space-x-3 mb-4">
                <svg className="w-8 h-8 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                </svg>
                <h3 className="text-xl font-semibold text-gray-800">{folder.name}</h3>
              </div>
              <p className="text-gray-600">
                {folder.opportunity_count || 0} opportunities
              </p>
              <div className="mt-4 text-blue-500 group-hover:text-blue-600">
                View Contents →
              </div>
            </Link>
          ))}
          
          {/* Create Folder Button */}
          <button
            onClick={() => setShowFolderModal(true)}
            className="bg-gray-50 rounded-xl p-6 border-2 border-dashed border-gray-200 hover:border-blue-300 hover:bg-gray-100 transition-all duration-300 flex flex-col items-center justify-center text-gray-500 hover:text-blue-500"
          >
            <svg className="w-12 h-12 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            Create New Folder
          </button>
        </div>
      </div>

      {/* Modals */}
      <CreateFolderModal
        show={showFolderModal}
        onClose={() => setShowFolderModal(false)}
        onSave={fetchFolders}
      />

      {/* Loading Spinner */}
      {loading && (
        <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-600"></div>
        </div>
      )}
    </div>
  );
}

export default SavedOpportunities;