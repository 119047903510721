import React from 'react';

function PageTransition({ isVisible }) {
  return (
    <div 
      className={`fixed inset-0 bg-gradient-to-b from-blue-50 via-white to-blue-50 z-[9999] flex items-center justify-center transition-all duration-500
        ${isVisible ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
      style={{ pointerEvents: isVisible ? 'auto' : 'none' }}
    >
      <div className="text-center">
        <div className="relative">
          <div className="absolute inset-0 bg-blue-400 filter blur-xl opacity-30 animate-pulse"></div>
          <svg className="w-16 h-16 mx-auto text-blue-600 animate-float" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
          </svg>
        </div>
        <div className="mt-4">
          <div className="animate-pulse">
            <div className="h-2 w-24 bg-blue-200 rounded mx-auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTransition;
