import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Only check for user data if we're not on the login or landing page
    if (location.pathname !== '/login' && location.pathname !== '/') {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          setUser(parsedUser);
        } catch (e) {
          console.error('Error parsing user data:', e);
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          if (location.pathname !== '/login') {
            navigate('/login');
          }
        }
      } else if (location.pathname !== '/login' && location.pathname !== '/') {
        navigate('/login');
      }
    } else {
      // Clear user state when on login or landing page
      setUser(null);
    }
  }, [navigate, location.pathname]);

  const handleNavigation = (path) => {
    if (user) {
      navigate(path);
    } else {
      navigate('/login');
    }
  };

  const handleLogout = async () => {
    try {
      // Update localhost to 127.0.0.1
      await fetch('http://127.0.0.1:5000/logout', {
        credentials: 'include',
      });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      navigate('/');
    }
  };

  return (
    <header className="sticky top-0 z-50 bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg shadow-sm">
      <div className="container mx-auto px-6 py-4">
        <div className="flex justify-between items-center">
          <button 
            onClick={() => handleNavigation('/')} 
            className="flex items-center space-x-2 group"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-600 group-hover:scale-110 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
            </svg>
            <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
              Procure Pro
            </span>
          </button>

          <nav className="flex items-center space-x-8">
            {location.pathname !== '/' && (
              <button
                onClick={() => handleNavigation('/home')}
                className="nav-link text-gray-600 hover:text-blue-600 transition duration-300 relative group"
              >
                Home
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </button>
            )}
            
            {user ? (
              <>
                <button
                  onClick={() => handleNavigation('/saved')}
                  className="nav-link text-gray-600 hover:text-blue-600 transition duration-300 relative group"
                >
                  Saved
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                </button>
                <div className="flex items-center space-x-4">
                  <span className="px-4 py-2 bg-gray-100 rounded-full text-sm font-medium text-gray-700">
                    {user.name}
                  </span>
                  <button
                    onClick={handleLogout}
                    className="px-4 py-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200 transition duration-300 text-sm font-medium flex items-center space-x-1"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <span>Logout</span>
                  </button>
                </div>
              </>
            ) : location.pathname !== '/login' && (
              <Link
                to="/login"
                className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 text-sm font-medium shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
              >
                Login
              </Link>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header; 