import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import OpportunityCard from '../components/opportunities/OpportunityCard';
import OpportunityModal from '../components/opportunities/OpportunityModal';
import { useOpportunities } from '../hooks/useOpportunities';
import { endpoints } from '../config/api';

function FolderView() {
  const { folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const { opportunities = [], loading: oppsLoading, error } = useOpportunities({}, folderId);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFolderDetails = async () => {
      setLoading(true);
      try {
        // First try to get folder details from the folders endpoint
        const response = await fetch(`${endpoints.folders.get}/${folderId}`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch folder details');
        
        const data = await response.json();
        if (data && data.name) {
          setFolder(data);
        } else {
          // Fallback to getting all folders and finding the matching one
          const allFoldersResponse = await fetch(endpoints.folders.getAll, {
            credentials: 'include'
          });
          const allFolders = await allFoldersResponse.json();
          const matchingFolder = allFolders.find(f => f.id === parseInt(folderId));
          if (matchingFolder) {
            setFolder(matchingFolder);
          }
        }
      } catch (error) {
        console.error('Error fetching folder details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFolderDetails();
  }, [folderId]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50">
      <div className="container mx-auto py-12">
        {/* Header with Back Button */}
        <div className="max-w-7xl mx-auto mb-8">
          <Link
            to="/saved"
            className="inline-flex items-center text-blue-600 hover:text-blue-700 mb-6"
          >
            ← Back to Folders
          </Link>
          <h1 className="text-3xl font-bold text-gray-900">
            {loading ? 'Loading...' : folder?.name || 'Unnamed Folder'}
          </h1>
          <p className="text-gray-600 mt-2">
            {Array.isArray(opportunities) ? opportunities.length : 0} opportunities
          </p>
        </div>

        {/* Loading State */}
        {(loading || oppsLoading) && (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        )}

        {/* Error State */}
        {error && (
          <div className="text-center py-12 text-red-600">
            Error loading opportunities: {error}
          </div>
        )}

        {/* Opportunities Grid */}
        {!loading && !error && Array.isArray(opportunities) && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {opportunities.map(opportunity => (
              <OpportunityCard
                key={opportunity.id}
                opportunity={opportunity}
                onClick={() => setSelectedOpportunity(opportunity)}
              />
            ))}
          </div>
        )}

        {/* Empty State */}
        {!loading && !error && (!Array.isArray(opportunities) || opportunities.length === 0) && (
          <div className="text-center py-12">
            <p className="text-gray-500">No opportunities in this folder yet.</p>
          </div>
        )}

        {/* Modal */}
        {selectedOpportunity && (
          <OpportunityModal
            show={!!selectedOpportunity}
            opportunity={selectedOpportunity}
            onClose={() => setSelectedOpportunity(null)}
          />
        )}
      </div>
    </div>
  );
}

export default FolderView; 