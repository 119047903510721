import React from 'react';
import OpportunityCard from './OpportunityCard';

function ResultsGrid({ opportunities, onOpportunityClick, currentPage, onPageChange, totalPages, folders }) {
  // Calculate the range of pages to show (e.g., show 5 pages at a time)
  const getPageRange = () => {
    const range = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }
    return range;
  };

  return (
    <div className="max-w-7xl mx-auto">
      {opportunities.length === 0 ? (
        <div className="text-gray-700 text-center w-full">
          No opportunities found in the database.
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {opportunities.map(opportunity => (
              <OpportunityCard
                key={opportunity.id || Math.random()}
                opportunity={opportunity}
                onClick={onOpportunityClick}
                folders={folders}
              />
            ))}
          </div>
          
          <div className="flex justify-center items-center mt-8 space-x-2">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage <= 1}
              className={`px-4 py-2 rounded transition-colors duration-200 ${
                currentPage <= 1
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-blue-100 text-blue-700 hover:bg-blue-200 cursor-pointer'
              }`}
            >
              Previous
            </button>
            
            {getPageRange().map((pageNum) => (
              <button
                key={pageNum}
                onClick={() => onPageChange(pageNum)}
                className={`px-4 py-2 rounded transition-colors duration-200 ${
                  currentPage === pageNum
                    ? 'bg-blue-500 text-white'
                    : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                }`}
              >
                {pageNum}
              </button>
            ))}
            
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage >= totalPages}
              className={`px-4 py-2 rounded transition-colors duration-200 ${
                currentPage >= totalPages
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-blue-100 text-blue-700 hover:bg-blue-200 cursor-pointer'
              }`}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ResultsGrid; 