import React, { useState, useRef } from 'react';

function OpportunityAIChat({ opportunity, onError }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initializationStep, setInitializationStep] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const inputRef = useRef(null);

  const formatInitialSummary = (text) => {
    // Check if this is a summary message
    if (!text.startsWith("Here's a summary")) {
      return text;
    }

    // Split into sections with better formatting
    const sections = {
      'CONTRACT OVERVIEW': [],
      'PURPOSE & SCOPE': [],
      'KEY REQUIREMENTS': [],
      'EVALUATION CRITERIA': [],
      'PRICING': [],
      'ADMINISTRATIVE': []
    };

    let currentSection = 'CONTRACT OVERVIEW';
    
    // Split by line breaks and clean up
    const lines = text.split(/[.\n]/)
      .map(line => line.trim())
      .filter(Boolean)
      .map(line => line.replace(/^[•-]\s*/, '')); // Remove bullet points
    
    lines.forEach(line => {
      // Check if line starts new section
      const sectionMatch = Object.keys(sections).find(section => 
        line.toUpperCase().includes(section)
      );
      
      if (sectionMatch) {
        currentSection = sectionMatch;
      } else if (line) {
        sections[currentSection].push(line);
      }
    });

    // Format each section with improved styling
    return Object.entries(sections)
      .filter(([_, lines]) => lines.length > 0)
      .map(([section, lines]) => {
        return `### ${section}\n${lines.map(line => `• ${line}`).join('\n')}`;
      })
      .join('\n\n');
  };

  const initializeChat = async (forceNew = false) => {
    console.log('Starting chat initialization...');
    setIsLoading(true);
    setInitializationStep('gathering');
    
    try {
      const contractUrl = opportunity.contract_url || opportunity.uiLink || opportunity.ui_link;
      
      if (!contractUrl) {
        throw new Error('No contract URL available');
      }

      const response = await fetch('/api/ai/initialize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          opportunityId: opportunity.id,
          contractUrl: contractUrl,
          forceNew: forceNew
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to initialize AI chat');
      }

      const data = await response.json();
      setConversationId(data.conversationId);
      
      if (data.isExisting) {
        const historyResponse = await fetch(`/api/ai/chat-history/${data.conversationId}`);
        if (historyResponse.ok) {
          const historyData = await historyResponse.json();
          setMessages(historyData.messages);
        }
      } else {
        const summaryResponse = await fetch('/api/ai/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            conversationId: data.conversationId,
            message: "Please provide a summary of the contract documents following the exact format specified."
          }),
        });

        if (summaryResponse.ok) {
          const summaryData = await summaryResponse.json();
          setMessages([{ 
            role: 'assistant', 
            content: formatInitialSummary(summaryData.reply)
          }]);
        } else {
          setMessages([{ 
            role: 'assistant', 
            content: 'I have analyzed the contract details. What would you like to know?' 
          }]);
        }
      }
      
      setIsInitialized(true);
      onError(null);
    } catch (error) {
      console.error('Error in initializeChat:', error);
      onError(error.message || 'Failed to initialize AI chat. Please try again.');
    } finally {
      setIsLoading(false);
      setInitializationStep(null);
    }
  };

  const LoadingScreen = () => (
    <div className="flex flex-col items-center justify-center space-y-4 p-8">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      <p className="text-lg font-medium text-gray-700">
        {initializationStep === 'gathering' 
          ? 'AI is downloading and analyzing contract documents...'
          : 'Processing documents...'}
      </p>
    </div>
  );

  const StartChatButton = () => (
    <div className="flex flex-col items-center justify-center p-8">
      <div className="space-y-4">
        <button
          onClick={() => initializeChat(false)}
          className="px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 
                     transform hover:-translate-y-0.5 transition-all duration-300 
                     shadow-md hover:shadow-xl"
        >
          Continue Chat
        </button>
        <button
          onClick={() => initializeChat(true)}
          className="px-6 py-3 bg-gray-600 text-white rounded-xl hover:bg-gray-700 
                     transform hover:-translate-y-0.5 transition-all duration-300 
                     shadow-md hover:shadow-xl"
        >
          Start New Chat
        </button>
      </div>
      <p className="mt-4 text-sm text-gray-600">
        Continue existing chat or start a new one
      </p>
    </div>
  );

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const message = inputRef.current.value.trim();
    if (!message || isLoading || !conversationId) return;

    inputRef.current.value = '';
    setMessages(prev => [...prev, { role: 'user', content: message }]);
    setIsLoading(true);

    try {
      const response = await fetch('/api/ai/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          conversationId: conversationId,
          message: message
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const data = await response.json();
      setMessages(prev => [...prev, { role: 'assistant', content: data.reply }]);
      onError(null);
    } catch (error) {
      console.error('Error sending message:', error);
      onError('Failed to send message. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isInitialized && !isLoading) {
    return <StartChatButton />;
  }

  if (isLoading && !isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <div className="flex flex-col h-[600px]">
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-4 whitespace-pre-wrap ${
                message.role === 'user'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-800'
              }`}
            >
              {/* Add markdown rendering here if using a markdown library */}
              {message.content}
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={handleSendMessage} className="p-4 border-t">
        <div className="flex space-x-4">
          <input
            ref={inputRef}
            type="text"
            placeholder="Ask about the contract..."
            className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default OpportunityAIChat;
