import React, { useState } from 'react';
import { formatDate, getDaysRemaining, getDeadlineColor, getDeadlineText } from '../../utils/formatters';

function OpportunityCard({ opportunity, onClick, folders }) {
  const [isMoving, setIsMoving] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState('');
  
  const folderNames = opportunity.folder_names?.join(', ');
  const daysRemaining = getDaysRemaining(opportunity.response_deadline);
  const deadlineColor = getDeadlineColor(daysRemaining);
  const deadlineText = getDeadlineText(daysRemaining);

  const handleMoveToFolder = async (e) => {
    e.stopPropagation(); // Prevent card click event
    const folderId = selectedFolder;
    
    if (!folderId) return;

    try {
      const response = await fetch('/api/assign_to_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          opportunityId: opportunity.id,
          folderId: folderId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to move opportunity');
      }

      // Reset states after successful move
      setIsMoving(false);
      setSelectedFolder('');
      
      // You might want to trigger a refresh of the opportunities list here
      
    } catch (error) {
      console.error('Error moving opportunity:', error);
    }
  };

  return (
    <div className="opportunity-card bg-white rounded-xl shadow-md p-6 hover:shadow-xl transition-all duration-300 ease-in-out border border-gray-100 hover:border-blue-100">
      <div className="flex justify-between items-start mb-4">
        <h3 className="font-semibold text-lg text-blue-700 flex-1 hover:text-blue-800 transition-colors">
          {opportunity.title || 'No Title'}
        </h3>
        <div className={`ml-4 flex flex-col items-end ${deadlineColor} backdrop-blur-sm px-3 py-1 rounded-lg`}>
          <span className="text-lg font-bold">{deadlineText}</span>
          <span className="text-xs opacity-75">remaining</span>
        </div>
      </div>
      
      <div className="flex justify-between items-center text-sm mb-4 bg-gray-50 p-2 rounded-lg">
        <span className="text-gray-600 flex items-center">
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          {formatDate(opportunity.response_deadline)}
        </span>
        <span className="text-green-600 font-medium bg-green-50 px-2 py-1 rounded-md text-xs">
          {opportunity.type_of_set_aside || 'None'}
        </span>
      </div>
      
      <div className="text-sm text-gray-600 mt-2 flex justify-between items-center border-t pt-4">
        <span className="flex items-center">
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
          </svg>
          {folderNames || 'None'}
        </span>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsMoving(!isMoving);
          }}
          className="text-blue-500 hover:text-blue-700 text-sm hover:underline transition-colors flex items-center"
        >
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
          </svg>
          Move
        </button>
      </div>

      {/* Folder Selection UI - Enhanced styling */}
      {isMoving && (
        <div className="mt-4 space-y-2 bg-gray-50 p-3 rounded-lg border border-gray-200" onClick={e => e.stopPropagation()}>
          <select
            value={selectedFolder}
            onChange={(e) => setSelectedFolder(e.target.value)}
            className="w-full p-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
          >
            <option value="">Select a folder...</option>
            {folders?.map(folder => (
              <option key={folder.id} value={folder.id}>
                {folder.name}
              </option>
            ))}
          </select>
          <div className="flex space-x-2">
            <button
              onClick={handleMoveToFolder}
              disabled={!selectedFolder}
              className="px-3 py-1.5 bg-blue-500 text-white rounded-lg text-sm hover:bg-blue-600 disabled:opacity-50 transition-colors flex-1"
            >
              Move
            </button>
            <button
              onClick={() => {
                setIsMoving(false);
                setSelectedFolder('');
              }}
              className="px-3 py-1.5 bg-gray-200 text-gray-700 rounded-lg text-sm hover:bg-gray-300 transition-colors flex-1"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      
      <button 
        onClick={() => onClick(opportunity)}
        className="mt-4 w-full bg-blue-500 text-white py-2.5 rounded-lg hover:bg-blue-600 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow-sm hover:shadow-md"
      >
        View Details
      </button>
    </div>
  );
}

export default OpportunityCard; 