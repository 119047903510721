import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkAuth } from '../../utils/auth';

function PrivateRoute({ children }) {
    const [authChecked, setAuthChecked] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let mounted = true;

        const verifyAuth = async () => {
            // Check local storage first
            const cachedAuth = localStorage.getItem('authStatus');
            const cachedTimestamp = localStorage.getItem('authTimestamp');
            
            // Use cached value if it's less than 5 minutes old
            if (cachedAuth && cachedTimestamp && 
                (Date.now() - parseInt(cachedTimestamp)) < 5 * 60 * 1000) {
                setIsAuthenticated(cachedAuth === 'true');
                setAuthChecked(true);
                return;
            }

            // Otherwise check with server
            try {
                const authenticated = await checkAuth();
                if (mounted) {
                    setIsAuthenticated(authenticated);
                    setAuthChecked(true);
                    
                    // Cache the result
                    localStorage.setItem('authStatus', authenticated.toString());
                    localStorage.setItem('authTimestamp', Date.now().toString());
                }
            } catch (error) {
                console.error('Auth check error:', error);
                if (mounted) {
                    setIsAuthenticated(false);
                    setAuthChecked(true);
                }
            }
        };

        verifyAuth();

        return () => {
            mounted = false;
        };
    }, [location.pathname]);

    if (!authChecked) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default PrivateRoute;
