import React, { useState, useEffect, useCallback } from 'react';
import { endpoints } from '../../config/api';

function AdvancedFilters({ show, onApplyFilters }) {
  const [filters, setFilters] = useState({
    setAsideTypes: [],
    sortOrder: 'newest',
    currentDateOnly: false,
    excludeDLA: false
  });
  const [availableSetAsides, setAvailableSetAsides] = useState([]);

  useEffect(() => {
    // Fetch available set-aside types when component mounts
    const fetchSetAsideTypes = async () => {
      try {
        const response = await fetch(`${endpoints.opportunities.setAsideTypes}`, {
          credentials: 'include'
        });
        const data = await response.json();
        if (data.success) {
          setAvailableSetAsides(data.types);
        }
      } catch (error) {
        console.error('Error fetching set-aside types:', error);
      }
    };

    fetchSetAsideTypes();
  }, []);

  const handleSetAsideChange = useCallback((type) => {
    setFilters(prev => {
      const updatedTypes = prev.setAsideTypes.includes(type)
        ? prev.setAsideTypes.filter(t => t !== type)
        : [...prev.setAsideTypes, type];
      
      return { ...prev, setAsideTypes: updatedTypes };
    });
  }, []);

  const handleApplyFilters = useCallback(() => {
    // Ensure we're passing the most current state
    onApplyFilters(filters);
  }, [filters, onApplyFilters]);

  if (!show) return null;

  return (
    <div className="max-w-4xl mx-auto mb-8 transition-all duration-300">
      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Set-Aside Types */}
          <div>
            <h3 className="font-semibold mb-3">Set-Aside Type</h3>
            <div className="space-y-2" id="setAsideCheckboxes">
              {availableSetAsides.map((type) => (
                <label key={type} className="flex items-center space-x-2">
                  <input 
                    type="checkbox" 
                    checked={filters.setAsideTypes.includes(type)}
                    onChange={() => handleSetAsideChange(type)}
                    className="form-checkbox text-blue-500" 
                  />
                  <span>{type}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Due Date Sorting */}
          <div>
            <h3 className="font-semibold mb-3">Due Date</h3>
            <select 
              value={filters.sortOrder}
              onChange={(e) => setFilters(prev => ({ ...prev, sortOrder: e.target.value }))}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-300"
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
              <option value="closing_soon">Closing Soon</option>
            </select>
          </div>

          {/* Additional Filters */}
          <div>
            <h3 className="font-semibold mb-3">Additional Filters</h3>
            <div className="space-y-2">
              <label className="flex items-center space-x-2">
                <input 
                  type="checkbox" 
                  checked={filters.currentDateOnly}
                  onChange={(e) => setFilters(prev => ({ ...prev, currentDateOnly: e.target.checked }))}
                  className="form-checkbox text-blue-500" 
                />
                <span>Current Opportunities Only</span>
              </label>
              <label className="flex items-center space-x-2">
                <input 
                  type="checkbox" 
                  checked={filters.excludeDLA}
                  onChange={(e) => setFilters(prev => ({ ...prev, excludeDLA: e.target.checked }))}
                  className="form-checkbox text-blue-500" 
                />
                <span>Exclude DLA Opportunities</span>
              </label>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <button
            onClick={handleApplyFilters}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150"
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(AdvancedFilters); 