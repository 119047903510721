import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Layout from './components/common/Layout';
import RouteTransition from './components/common/RouteTransition';
import Home from './pages/Home';
import Login from './pages/Login';
import Landing from './pages/Landing';
import SavedOpportunities from './pages/SavedOpportunities';
import PrivateRoute from './components/common/PrivateRoute';
import FolderView from './pages/FolderView';

function App() {
  useEffect(() => {
    // Clear stale auth state on initial load
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.removeItem('user');
    }
  }, []);

  return (
    <Router>
      <Layout>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route 
              path="/home" 
              element={
                <PrivateRoute>
                  <RouteTransition>
                    <Home />
                  </RouteTransition>
                </PrivateRoute>
              } 
            />
            <Route 
              path="/saved" 
              element={
                <PrivateRoute>
                  <RouteTransition>
                    <SavedOpportunities />
                  </RouteTransition>
                </PrivateRoute>
              } 
            />
            <Route path="/folders/:folderId" element={<FolderView />} />
          </Routes>
        </AnimatePresence>
      </Layout>
    </Router>
  );
}

export default App;
