import { useState, useEffect } from 'react';
import { endpoints } from '../config/api';
import { api } from '../utils/api';

export function useOpportunities(initialFilters = {}, folderId = null) {
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const fetchOpportunities = async () => {
      setLoading(true);
      try {
        let response;
        if (folderId) {
          // Fetch folder-specific opportunities
          response = await fetch(`${endpoints.folders.getContents}/${folderId}`, {
            credentials: 'include'
          });
          const data = await response.json();
          // Ensure we're setting an array
          setOpportunities(Array.isArray(data) ? data : data.opportunities || []);
        } else {
          // Fetch filtered opportunities
          const result = await api.searchOpportunities('', filters);
          setOpportunities(result.opportunities || []);
        }
        setError(null);
      } catch (err) {
        console.error('Error fetching opportunities:', err);
        setError(err.message);
        setOpportunities([]); // Set empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, [filters, folderId]);

  return { opportunities, loading, error, setFilters };
}
