import React, { useState } from 'react';
import Modal from '../common/Modal';
import OpportunityAIChat from './OpportunityAIChat';

function OpportunityModal({ show, opportunity, onClose, folders, onUpdate }) {
  const [activeTab, setActiveTab] = useState('info');
  const [aiError, setAiError] = useState(null);
  
  if (!opportunity) return null;

  const formatDate = (isoString) => {
    if (!isoString) return 'Not specified';
    return new Date(isoString).toLocaleString();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="space-y-6 max-w-4xl mx-auto">
        <div className="border-b border-gray-100 pb-4">
          <h2 className="text-2xl font-bold text-blue-800 leading-tight">
            {opportunity.title}
          </h2>
          <p className="text-gray-500 text-sm mt-2">
            {opportunity.solicitation_number}
          </p>
        </div>
        
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {['info', 'notes', 'ai'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${
                  activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ease-in-out`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
        </div>

        {activeTab === 'info' && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-6">
                {[
                  { label: 'Solicitation Number', value: opportunity.solicitation_number },
                  { label: 'Response Deadline', value: formatDate(opportunity.response_deadline) },
                  { label: 'Posted Date', value: formatDate(opportunity.posted_date) },
                  { label: 'Set-Aside', value: opportunity.type_of_set_aside }
                ].map(({ label, value }) => (
                  <div key={label} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                    <h3 className="text-sm font-medium text-gray-500">{label}</h3>
                    <p className="mt-1 text-gray-900 font-medium">{value || 'Not specified'}</p>
                  </div>
                ))}
              </div>
              
              <div className="space-y-6">
                {[
                  { label: 'Agency', value: opportunity.agency },
                  { label: 'NAICS Code', value: opportunity.naics_code },
                  { label: 'Place of Performance', value: opportunity.place_of_performance },
                  { label: 'Current Folder', value: opportunity.folder_names?.join(', ') }
                ].map(({ label, value }) => (
                  <div key={label} className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                    <h3 className="text-sm font-medium text-gray-500">{label}</h3>
                    <p className="mt-1 text-gray-900 font-medium">{value || 'Not specified'}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-8">
              <h3 className="text-sm font-medium text-gray-500 mb-3">Description</h3>
              <div className="prose max-w-none bg-white p-6 rounded-lg border border-gray-100 shadow-sm">
                {opportunity.description || 'No description available.'}
              </div>
            </div>
          </>
        )}

        {activeTab === 'notes' && (
          <div className="space-y-8">
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="text-sm font-medium text-gray-500 mb-3">Notes</h3>
              <textarea
                className="w-full h-32 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                placeholder="Add your notes here..."
                value={opportunity.notes || ''}
                onChange={(e) => {
                  // Add note update functionality here
                }}
              />
            </div>

            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="text-sm font-medium text-gray-500 mb-3">Uploaded Files</h3>
              <div className="space-y-3">
                {opportunity.uploaded_files?.length > 0 ? (
                  opportunity.uploaded_files.map((file) => (
                    <div key={file.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                      <span className="font-medium text-gray-700">{file.filename}</span>
                      <div className="flex space-x-3">
                        <a
                          href={file.file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800 transition-colors"
                        >
                          View
                        </a>
                        <button className="text-red-600 hover:text-red-800 transition-colors">
                          Delete
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500 italic">No files uploaded yet</p>
                )}
                
                <div className="mt-4">
                  <label
                    htmlFor="file-upload"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors cursor-pointer"
                  >
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                    Upload File
                  </label>
                  <input type="file" className="hidden" id="file-upload" />
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'ai' && (
          <div className="space-y-4">
            {aiError && (
              <div className="text-red-600 bg-red-50 p-4 rounded-lg border border-red-100">
                {aiError}
              </div>
            )}
            <OpportunityAIChat 
              opportunity={opportunity} 
              onError={setAiError}
            />
          </div>
        )}

        <div className="flex justify-between space-x-4 mt-8 pt-6 border-t border-gray-100">
          <a
            href={opportunity.contract_url}
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transform hover:-translate-y-0.5 transition-all duration-300 shadow-sm hover:shadow-md inline-flex items-center"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
            View on SAM.gov
          </a>
          <button
            onClick={onClose}
            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-all duration-300 inline-flex items-center"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default OpportunityModal; 