import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import PageTransition from './PageTransition';

function Layout({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Initial load sequence
    setIsLoading(true);
    setShowContent(false);

    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <PageTransition isVisible={isLoading} />
      <div className={`transition-opacity duration-500 ${showContent ? 'opacity-100' : 'opacity-0'}`}>
        <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 font-inter text-gray-900">
          <Header />
          <main className="flex-grow">
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Layout;
